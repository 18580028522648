<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <modalVideo ref="modalVideo" :videoUrl="videoData"></modalVideo>

    <MainBanner
      v-if="this.infographic"
      @clicked="showModal"
      :SmallTitle="this.infographic.desc"
      :Title="this.infographic.student.toLocaleString('en')"
      Desc=""
      ButtonText="Bağış Yap"
      OverlapImage="/svg/toplanan-bagis.svg"
      TextPosition="between"
      Height="middle"
    />

    <div class="main-continer">
      <section class="section-padding container">
        <ToplananBagisInfographic
          :countTeacher="this.infographic.teacher"
          :countSchool="this.infographic.school"
          :countStudent="this.infographic.student"
        />
      </section>

      <section class="carousel-ogretmen section-padding" id="ogretmen-hikayeleri" v-if="this.pageContent">
        <div class="floating-carousel-info text-center" v-html="this.pageContent.page[0].content"></div>

        <MultipleCarousel
          v-if="teacherData.length > 0"
          cardColor="green"
          label="ogretmen-hikayeleri"
          nav="second"
          :cardData="teacherData"
          @clicked="openVideo"
        />
      </section>

      <section class="carousel-ogrenci section-padding" id="ogrenci-hikayeleri" v-if="this.pageContent">
        <div class="floating-carousel-info text-center" v-html="this.pageContent.page[1].content"></div>

        <MultipleCarousel
          v-if="studentData.length > 0"
          cardColor="orange"
          label="ogrenci-hikayeleri"
          nav="first"
          :cardData="studentData"
          cardPosition="float-md-right"
          @clicked="openVideo"
        />
      </section>

      <section class="section-padding" id="bilim-senlikleri" v-if="this.pageContent">
        <div class="floating-carousel-info text-center" v-html="this.pageContent.page[2].content"></div>

        <div
          class="swiper-carousel swiper-small swiper-destekciler container position-relative"
          v-if="festData.length > 0"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide play-video text-center"
              @click="showModalVideo(fest.video)"
              v-for="(fest, index) in festData"
              :key="index"
            >
              <span class="svg-icon icon-video"></span>
              <img :src="fest.image" />
              <h3 class="name">{{ fest.title }}</h3>
              <p class="title small">{{ fest.desc }}</p>
            </div>
          </div>
          <div class="swiper-button swiper-button-destekciler swiper-button-next d-none d-md-flex"></div>
              <div class="swiper-button swiper-button-destekciler swiper-button-prev d-none d-md-flex"></div>
          <!-- <div class="swiper-pagination"></div> -->
        </div>
      </section>

      <div class="spacer clearfix"></div>

      <FooterLanding />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
import FloatingCard from "@/components/FloatingCard";
import MultipleCarousel from "@/components/MultipleCarousel";
import ModalVideo from "@/components/ModalVideo";

export default {
  name: "Hikayelerimiz",
  components: {
    MainBanner,
    FooterLanding,
    Loading,
    ModalBagisYap,
    ToplananBagisInfographic,
    FloatingCard,
    MultipleCarousel,
    ModalVideo,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      teacherData: {},
      studentData: {},
      festData: {},
      videoData: {},
      infographic: "",
      pageContent: '',
    };
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this._swiperInit();
    this.getStories();
    this.getFestivals();
    this.getPageContent("hikayelerimiz");
    function scrollToAnchor(url) {
      var urlHash = url.split("#")[1];
      if (urlHash && $("#" + urlHash).length) {
        $("html").animate(
          {
            scrollTop: $("#" + urlHash).offset().top - 60,
          },
          500
        );
      }
    }

    $(document).ready(function () {
      setTimeout(() => {
        scrollToAnchor(window.location.href);
      }, 300);
    });
    this.getInfographic();
  },
  watch: {
    $route(to, from) {
      this._swiperInit();
    },
  },
  methods: {
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
      });
    },
    getInfographic() {
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
    openVideo(data) {
      this.showModalVideo(data);
    },
    showModal() {
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    showModalVideo(data) {
      this.videoData = data;
      this.$refs.modalVideo.openModal(data);
    },
    getStories() {
      this.$api.getStories().then((response) => {
        this.teacherData = response.teacher;
        this.studentData = response.student;
      });
    },
    getFestivals() {
      this.$api.getFestivals().then((response) => {
        this.festData = response;
        //this.studentData = response.student;
        setTimeout(() => {
          this._swiperInit();
        }, 300);
      });
    },
    _swiperInit() {
      const swiperBilimSenlikleri = new Swiper(".swiper-destekciler", {
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.swiper-destekciler {
  .play-video{
    cursor: pointer;
  }
  
  .name {
    margin-top: 1rem;
  }
  img {
    width: 100%;
  }
  .svg-icon {
    background: var(--purpleLight);
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>